import {
  Container,
  Typography,
  TextField,
  Box,
} from "@material-ui/core"
import React, { useState } from "react"
import axios from "axios"
import Dots from "../components/Dots"
import _c from "../uiConfig/colors"
import BottomPriceButtonMobile from "../components/BottomPriceButtonMobile"
import BottomPriceButtonDesktop from "../components/BottomPriceButtonDesktop"
import styled from "styled-components"
import MetaHeader from "../components/MetaHeader"
import { navigate, graphql } from "gatsby"
import BigScreenMapImage from "../components/BigScreenMapImage"
import Autocomplete from "@material-ui/lab/Autocomplete"
import validateEmail from "../utils/validateEmail"
import FadeLoader from "react-spinners/FadeLoader"

const FiltersScreen = ({ data }) => {
  const { allCustomTypesJson, allUsaCitiesJson } = data
  const email = useState("")
  const emailError = useState(false)

  const email2 = useState("")
  const email2Error = useState(false)
  const emailDontMarchError = useState(false)

  const business_type = useState("")
  const city = useState("")

  const request_in_process = useState(false)
  const requestTriggered = useState(false)

  const defineBtnLock = () => {
    const arr = [
      email2Error[0],
      emailDontMarchError[0],
      email[0] === "",
      email2[0] === "",
      business_type[0] === "",
      city[0] === "",
    ]
    return arr.includes(true)
  }
  const btnDisabledCondition = defineBtnLock()

  const requestList = () => {
    const types = allCustomTypesJson.edges
      .find(el => el.node.name === business_type[0].trim())
      .node.arr.join("+")
      .replace(/_/gi, "+")
    const _city = city[0].split("-")[0].trim()
    const data = {
      filter_settings: {
        types,
        city: _city,
      },
      email: email[0],
    }
    axios({
      method: "POST",
      url:
        "https://us-central1-vending-machine-app-9eb8c.cloudfunctions.net/performLocaionsExportPaidService",
      // "http://localhost:5001/vending-machine-app-9eb8c/us-central1/performLocaionsExportPaidService",
      data,
    })
      .then(res => {
        requestTriggered[1](false)
        request_in_process[1](false)
        navigate("/download", {
          state: { ...res.data },
          replace: true,
        })
      })
      .catch(err => {
        requestTriggered[1](false)
        request_in_process[1](false)
        console.error("ERROR! :::", err)
      })
  }
  const createEmailError = (invalidEmail, dontMatch) => {
    if (invalidEmail) {
      return "Invlid email."
    }
    if (dontMatch) {
      return "Emails don't match."
    }
  }
  const focusNextInput = (e, id) => {
    e.preventDefault()
    if (window && document) {
      if (e.key === "Enter" || e.key === "Return") {
        document.getElementById(id).focus()
      }
      // if (e.keyCode === 13)
    }
  }
  return (
    <MetaHeader>
      {request_in_process[0] && (
        <div
          style={{
            minHeight: "100vh",
            minWidth: "100vw",
            position: "absolute",
            zIndex: 5000,
            backgroundColor: _c.screen_background,
            top: 0,
            left: 0,
          }}
        >
          <div
            style={{
              position: "fixed",
              top: "calc(50% - 25px)",
              left: "calc(50% - 25px)",
            }}
          >
            <FadeLoader color={_c.main_red} loading={true} />
          </div>
        </div>
      )}

      <StyledContainer>
        <Dots dotsArrLength={3} selectedDotInd={0} />
        <Typography
          style={{ fontFamily: "Anton" }}
          variant="h4"
          component="h1"
          color={"primary"}
          gutterBottom
        >
          Set List Filters
        </Typography>
        <FormBox component={"main"}>
          <TF
            id={"email"}
            onKeyUp={e => focusNextInput(e, "confirm-email")}
            inputProps={{
              autoCapitalize: "off",
              autoComplete: "off",
            }}
            error={emailError[0] || emailDontMarchError[0]}
            value={email[0]}
            onChange={e => email[1](e.target.value)}
            onBlur={() => {
              emailDontMarchError[1](
                email[0] !== "" && email2[0] !== "" && email2[0] !== email[0]
              )
              emailError[1](!validateEmail(email[0]) && email[0] !== "")
            }}
            label="Email*"
            helperText={createEmailError(emailError[0], emailDontMarchError[0])}
          />
          <div
            style={{
              backgroundColor: "transparent",
              width: "100%",
              height: "10px",
            }}
          />
          <TF
            id={"confirm-email"}
            inputProps={{
              autoCapitalize: "off",
              autoComplete: "off",
            }}
            onKeyUp={e => focusNextInput(e, "business-type")}
            error={emailError[0] || emailDontMarchError[0]}
            value={email2[0]}
            onChange={e => email2[1](e.target.value)}
            onBlur={() => {
              emailDontMarchError[1](email2[0] !== email[0])
              emailDontMarchError[1](
                email[0] !== "" && email2[0] !== "" && email2[0] !== email[0]
              )
              email2Error[1](!validateEmail(email2[0]) && email2[0] !== "")
            }}
            label="Confirm Email*"
            helperText={createEmailError(
              email2Error[0],
              emailDontMarchError[0]
            )}
          />
          <Box component={"div"} mb={"10%"} mt={"10%"}>
            <Autocomplete
              disableClearable
              getOptionLabel={option => option.node.name}
              id="business-type"
              onChange={event => business_type[1](event.target.innerHTML)}
              options={allCustomTypesJson.edges}
              renderInput={params => (
                <TF
                  {...params}
                  onKeyUp={e => focusNextInput(e, "city-input")}
                  label="Business Type*"
                />
              )}
            />
          </Box>

          <Autocomplete
            disableClearable
            id="city-input"
            options={allUsaCitiesJson.edges}
            getOptionLabel={option =>
              `${option.node.city} - ${option.node.state}`
            }
            onChange={event => city[1](event.target.innerHTML)}
            renderInput={params => (
              <TF
                onKeyUp={e => focusNextInput(e, "email")}
                {...params}
                label="City*"
              />
            )}
          />
          <BottomPriceButtonDesktop
            label={"Order Now"}
            price={"Free"}
            buttonDisabled={btnDisabledCondition}
            onBtnPress={e => {
              e.preventDefault()
              request_in_process[1](true)
              requestTriggered[1](true)
              requestList()
            }}
          />
        </FormBox>
      </StyledContainer>
      <BottomPriceButtonMobile
        buttonDisabled={btnDisabledCondition}
        label={"Order Now"}
        price={"Free"}
        onBtnPress={e => {
          e.preventDefault()
          request_in_process[1](true)
          requestTriggered[1](true)
          requestList()
        }}
      />
      <BigScreenMapImage />
    </MetaHeader>
  )
}

export const placesTypesList = graphql`
  query MyQuery {
    # allPlacesTypesJson {
    #   edges {
    #     node {
    #       id
    #       name
    #     }
    #   }
    # }
    allCustomTypesJson {
      edges {
        node {
          name
          arr
        }
      }
    }
    allUsaCitiesJson {
      edges {
        node {
          id
          city
          state
        }
      }
    }
  }
`

const FormBox = styled(Box)`
  flex: 1;
  flex-direction: column;
  @media screen and (max-width: 650px) {
    padding-left: 3%;
    padding-right: 3%;
  }
`
const StyledContainer = styled(Container)`
  overflow: scroll;
  height: 115vh;
  /* max-height: 100vh; */
  display: block;
  flex-direction: column;

  @media screen and (orientation: landscape) and (max-height: 600px) {
    padding-bottom: 120px;
  }

  @media screen and (min-width: 650px) {
    padding: 5% 15%;
  }
`
// const StyledBox = styled(Box)`
//   display: flex;
//   justify-content: space-evenly;
//   max-width: 100%;
// `
const TF = styled(TextField)`
  font-family: Work Sans !important;
  font-weight: 100;
  width: 100%;
`
export default FiltersScreen
