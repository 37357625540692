import React from "react"
import _c from "../uiConfig/colors"
import styled from "styled-components"
import { Box, Button, Typography } from "@material-ui/core"
export default function BottomPriceButtonDesktop({
  price,
  label,
  onBtnPress,
  buttonDisabled,
}) {
  return (
    <StyledBox component={"div"}>
      <Half>
        <StyledTypography
          variant="subtitle1"
          component="span"
          color={"secondary"}
          gutterBottom
          children={"Total: "}
        />
        <Price
          variant="subtitle1"
          component="span"
          color={"primary"}
          gutterBottom
          children={`${price}`}
        />
      </Half>
      <Half>
        <StyledButton
          disabled={buttonDisabled}
          onClick={onBtnPress}
          variant={"contained"}
          color={"primary"}
        >
          {label}
        </StyledButton>
      </Half>
    </StyledBox>
  )
}
const StyledTypography = styled(Typography)`
  font-family: Work Sans;
  font-size: 1em;
  margin-bottom: 0;
  font-weight: 300;
`
const Price = styled(Typography)`
  font-family: Work Sans;
  font-size: 1.1em;
  margin: 0 10% 0 3%;
  font-weight: 500;
`
const StyledBox = styled(Box)`
  @media screen and (min-width: 650px) {
    height: 60px !important;
    /* background-color: ${_c.white}; */
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 2.5%;
    /* padding-left: 5%;
    padding-right: 5%; */
  }
  display: none;
`
const StyledButton = styled(Button)`
  background-color: #ffbf00;
  &:hover {
    background-color: #eeae00;
  }
  color: #fff;
  padding: 7px 14px;
  border-radius: 5px;
  min-width: 100%;
  text-transform: none;
  font-family: Work Sans;
`
const Half = styled(Box)`
  display: flex;
  max-width: 50%;
  flex: 1;
  align-items: center;
  justify-content: center;
`
